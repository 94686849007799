import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import ChangeLanguageIntegration from '../../entities/change-language-integration.entity';
// @ts-ignore
import Select from 'react-select';
import {ChangeLanguageContainer} from "../../stores/changeLanguage.store";
import {AxiosResponse} from "axios";
import ChatRoom from "../../entities/chat-room.entity";
import Integration from "../../entities/integration.entity";
import ChangeLanguageIntegrationResponse from "../../entities/change-language-integration-response.entity";

export interface ChangeLanguageComponentProps {
  integration: ChangeLanguageIntegration;
}

class SelectOption {
  value: string;
  label: string;
}

export function ChangeLanguageComponent({ integration }: ChangeLanguageComponentProps): ReactElement {
  const { error } = IntegrationsContainer.useContainer();
  const [integrationState, setIntegrationState] = useState(integration);
  const { postChangeLanguage } = ChangeLanguageContainer.useContainer();

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  const options: SelectOption[] = [];
  const defaultValue = {
    value: integrationState.current_locale.slug,
    label: integrationState.current_locale.label,
  };


  integrationState.available_locales.forEach(function(language) {
    options.push({
      value: language.slug,
      label: language.label,
    });
  });

  const submitLanguageChange = (newLanguage: SelectOption) => {
    postChangeLanguage(integrationState.submit_url, {
      selected_locale: newLanguage.value,
      current_path: window.location.pathname,
    }).then((res: AxiosResponse<ChangeLanguageIntegrationResponse>) => {
      console.log(res.data.url);

      if (typeof res.data.url === 'string') {
        window.location.replace(res.data.url);
      } else {
        location.reload();
      }
    });
  };

  return (
    <div className="change-language-dropdown" id="change-language-dropdown">
      <Select options={options} defaultValue={defaultValue} onChange={submitLanguageChange} className='language-select-container' classNamePrefix="language-select"/>
    </div>
  );
}
