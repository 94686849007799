import { ErrorMessage, Field, getIn } from 'formik';
import * as React from 'react';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const MIN_TEXTAREA_HEIGHT = 32;

const Textarea: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
  formik,
}: DefaultFieldComponentProps) => {
  const textareaRef = React.useRef(null);
  const [value, setValue] = React.useState(hoField.initial_value || '');
  const onChange = (event: { target: { value: string } }) => {
    formik.setFieldValue(hoField.name, event.target.value)
    setValue(event.target.value)
  };

  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = 'inherit';
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT,
    )}px`;
  }, [value]);

  return (
    <div
      className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${
        getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)
          ? 'ho-field-error-outer'
          : ''
      }`}>
      <label
        className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}
        htmlFor={hoField.name}>
        {hoField.label}
        {hoField.required &&
          <span  className="ho-required-star">*</span>
        }
      </label>
      <Field
        className={`ho-input ${hoField.input_css_class ? hoField.input_css_class : ''}`}
        as="textarea"
        innerRef={textareaRef}
        style={{
          minHeight: MIN_TEXTAREA_HEIGHT,
          resize: 'none',
        }}
        onChange={onChange}
        value={value}
        name={hoField.name}
      />
      <span className="ho-help-text">{hoField.helptext}</span>

      <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
    </div>
  );
};

export default Textarea;
