import { FieldInputProps, FieldHelperProps } from 'formik';
import { useContext, createContext } from 'react';
import FormField from "../../../../entities/form-field.entity";

interface Context {
  field: FieldInputProps<string[]>;
  helpers: FieldHelperProps<string[]>;
  formField: FormField;
}

const CheckboxContext = createContext<Context | null>(null);

export const useCheckboxContext = (): Context => {
  const context = useContext(CheckboxContext);

  if (!context) {
    throw new Error('Must be used in scope of a CheckboxProvider');
  }

  return context;
};

export const CheckboxProvider = CheckboxContext.Provider;
