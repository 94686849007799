import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { ErrorResponse } from './integration.store';
import { FormFieldValue } from '../entities/form-field-value.entity';
import ChatIntegration from '../entities/chat-integration.entity';

interface RatingInterface {
  postRating: (
    url: string,
    values: Record<string, FormFieldValue>,
  ) => Promise<AxiosResponse<object>>;
  postResponse: AxiosResponse<void>;
  error: ErrorResponse;
  resetPostError: () => void;
}

const useRating = (): RatingInterface => {
  const [postResponse, setPostResponse] = useState(null);
  const [error, setError] = useState<ErrorResponse>(null);

  const resetPostError = (): void => {
    setError(null);
  };

  const postRating = async (
    url: string,
    values: Record<string, FormFieldValue>,
  ): Promise<AxiosResponse<ChatIntegration>> => {
    try {
      const data = await axios.post(url, { payload: values });
      setPostResponse(data.data);
      return data.data;
    } catch (error) {
      setError(error.response?.data?.data);
      throw error;
    }
  };

  return {
    postRating,
    postResponse,
    error,
    resetPostError,
  };
};

export const RatingContainer = createContainer(useRating);
