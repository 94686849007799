import React, { ChangeEvent, ReactElement, SyntheticEvent, useReducer, useState } from 'react';
import RatingIntegration from '../../entities/rating-integration.entity';
import { AxiosResponse } from 'axios';
import { RatingContainer } from '../../stores/rating.store';

export interface RatingComponentProps {
  integration: RatingIntegration;
}

export function RatingComponent({ integration }: RatingComponentProps): ReactElement {
  const { error, postRating } = RatingContainer.useContainer();

  const [ratingElements, setRatingElements] = useState(null);
  const [integrationState, setIntegrationState] = useState(integration);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const isDisabled = (): boolean => {
    return integrationState.payload.allow_rating_once && integrationState.payload.voted_value > 0;
  };

  const [cssClasses, setCssClasses] = useState({
    outerSpan: 'rating-element ' + (isDisabled() ? 'disabled' : ''),
    innerInput: 'rating-element-inner-input',
    innerSpan: 'rating-element-inner-span ' + integrationState.payload.icon_span_class,
  });

  if (!ratingElements) {
    const html = [];

    if (integrationState.payload.inverse) {
      for (let i = integrationState.payload.max_value; i > 0; i--) {
        html.push(i);
      }
    } else {
      for (let i = 0; i < integrationState.payload.max_value; i++) {
        html.push(i + 1);
      }
    }

    setRatingElements(html);
  }

  const getActiveInnerSpanClass = (value: number): string => {
    console.log('active inner class...' + value + integrationState.payload.voted_value);
    if (value <= integrationState.payload.voted_value) {
      return (
        'rating-element-inner-span rating-element-voted ' + integrationState.payload.icon_span_class
      );
    }

    return cssClasses.innerSpan;
  };

  const submitRating = (evt: ChangeEvent, value: number) => {
    postRating(integrationState.submit_url, { value: value }).then(
      (res: AxiosResponse<RatingIntegration>) => {
        setIntegrationState(res.data);
        forceUpdate();
        return true;
      },
    );
  };

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  return (
    <div className="rating-container">
      <form className="rating-form">
        {ratingElements?.map((value: number) => {
          return (
            <span key={value} className={cssClasses.outerSpan}>
              <input
                className={cssClasses.innerInput}
                type="radio"
                name="rating"
                value={value}
                checked={integrationState.payload.voted_value === value}
                disabled={isDisabled()}
                onChange={(evt) => {
                  submitRating(evt, value);
                }}
              />
              <span className={getActiveInnerSpanClass(value)} />
            </span>
          );
        })}
      </form>
    </div>
  );
}
