import {ErrorMessage, Field, FieldInputProps, getIn} from 'formik';
import * as React from 'react';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const Select: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
  formik,
}: DefaultFieldComponentProps) => {
  return (
    <div className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
      <label
        className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}
        htmlFor={hoField.name}>
        {hoField.label}
        {hoField.required &&
            <span className="ho-required-star">*</span>
        }
      </label>

      <Field
        multiple={hoField.multiple}
        className={`ho-input ${hoField.input_css_class ? hoField.input_css_class : ''}`}
        name={hoField.name}>
        {({ field }: { field: FieldInputProps<any> }) => (
          <>
            <select
              multiple={hoField.multiple}
              className={`ho-input ${hoField.input_css_class ? hoField.input_css_class : ''}`}
              {...field}>
              <option value={null} aria-label="none" />

              {hoField.choices.map((choice: { label: string; value: string }) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
            <span className="ho-help-text">{hoField.helptext}</span>

            <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
          </>
        )}
      </Field>
    </div>
  );
};

export default Select;
