import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import SingleSignOnIntegration from '../../entities/single-sign-on-integration.entity';

export interface SingleSignOnComponentProps {
  integration: SingleSignOnIntegration;
}

export function SingleSignOnComponent({ integration }: SingleSignOnComponentProps): ReactElement {
  const { error } = IntegrationsContainer.useContainer();
  const [integrationState, setIntegrationState] = useState(integration);

  const redirectToSsoProvider = (e: SyntheticEvent) => {
    window.location.href = integrationState.submit_url;
  };

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  return (
    <div className="sso-button">
      <button onClick={redirectToSsoProvider}>{integration.translation.btn_text}</button>
    </div>
  );
}
