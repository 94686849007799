import React, { ReactElement } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import { ChatRoomsContainer } from '../../stores/chat.store';
import ChatMessages from '../../entities/chat-messages.entity';
import Linkify from 'react-linkify';

export interface ChatRoomMessageComponentProps {
  message: ChatMessages;
  displayLeft: boolean;
}

export function ChatRoomMessageComponent({
  message,
  displayLeft,
}: ChatRoomMessageComponentProps): ReactElement {
  const { postIntegration, error } = IntegrationsContainer.useContainer();
  const { useGetChatRooms } = ChatRoomsContainer.useContainer();

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  return (
    <div
      style={{
        textAlign: displayLeft ? 'left' : 'right',
        overflowWrap: 'anywhere',
        width: 'fit-content',
        marginLeft: displayLeft ? 'inherit' : 'auto',
      }}
      className="chat-message-container-outer">
      <div
        className={`alert ${
          displayLeft ? 'alert-primary' : 'alert-secondary'
        } chat-message-container-inner`}>
        <div className="chat-message-nickname">{message.sender.nickname}</div>
        <div className="chat-message">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="_blank" rel="noreferrer" href={decoratedHref} key={key} style={{textDecoration: 'underline'}}>
                {decoratedText}
              </a>
            )}>
            {message.message}
          </Linkify>
        </div>
      </div>
    </div>
  );
}
