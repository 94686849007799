import ActionType from './action-type.enum';

export enum MeetingSlotStatus {
  free = 'free',
  booked = 'booked',
  cancelled = 'cancelled',
  cancelled_overlap = 'cancelled_overlap',
  cancelled_external = 'cancelled_external',
  unknown = 'unknown',
}

export interface MeetingSlot {
  id: string;
  has_free_guest_slots: boolean;
  publish_status: string;
  meeting_start: string;
  meeting_start_readable_time: string;
  meeting_start_readable_date: string;
  meeting_end: string;
  meeting_end_readable_time: string;
  meeting_end_readable_date: string;
  meeting_link: string;
  meeting_duration: bigint;
  current_authenticated_guests_booking: boolean;
  slot_status: MeetingSlotStatus;
}

export class MeetingIntegration {
  public uuid: string;

  public type: ActionType;

  public submit_url: string;

  public payload: {
    slot_day_outer_classes: string;
    current_slot_day_text: string;
    success_message_text: string;
    cancel_booking_button_text: string;
    cancel_booking_confirmation_text: string;
    booked_text: string;
    locale: string;
    slot_button_outer_classes: string;
    slot_button_classes: string;
    slot_button_booked_classes: string;
    current_authenticated_guests_booking: boolean;
    booked_slot?: MeetingSlot;
    slots: MeetingSlot[];
  };
}

export default MeetingIntegration;
