import React, { ReactElement, SyntheticEvent, useReducer, useRef, useState } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import axios, { AxiosResponse } from 'axios';
import ChatRoom, { ChatMember } from '../../entities/chat-room.entity';
import { ChatRoomsContainer } from '../../stores/chat.store';
import ChatMessages from '../../entities/chat-messages.entity';
import { ChatRoomMessageComponent } from './chat-room-message.component';
import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;
import Pusher from 'pusher-js';
import config from '../../config/config';
import ChatIntegration from "../../entities/chat-integration.entity";

export interface ChatRoomComponentProps {
  chatRoom: ChatRoom;
  integration: ChatIntegration;
}

export function ChatRoomComponent({ chatRoom, integration }: ChatRoomComponentProps): ReactElement {
  const { error } = IntegrationsContainer.useContainer();
  const { useGetChatRoomMessages, postMessage } = ChatRoomsContainer.useContainer();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const messagesEndRef = useRef(null);
  let message: HTMLInputElement;

  const [chatRoomMessages, setChatRoomMessages] = useState(chatRoom.messages);
  const [pusherChannel, setPusherChannel] = useState(null);
  const [pusher, setPusher] = useState(null);

  const GetChatRoomMessages = (): void => {
    useGetChatRoomMessages(chatRoom.messages_url).then((res: AxiosResponse<ChatMessages[]>) => {
      console.log('fetching messages from... ' + chatRoom.messages_url);
      setChatRoomMessages(res.data);
    });
  };

  if (!chatRoomMessages) {
    GetChatRoomMessages();
    return <div>{integration.translation.loading_messages}</div>;
  }

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  function scrollToBottom(): void {
    const chatBody = document.getElementById('chat-body');
    chatBody.scrollTop = chatBody.scrollHeight;
    //messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  function addNewMessage(message: ChatMessages): void {
    if (!chatRoomMessages.find((m) => m.id === message.id)) {
      chatRoomMessages.push(message);
      setChatRoomMessages(chatRoomMessages);
      forceUpdate();
      scrollToBottom();
    } else {
      console.log('message already within messages object :)');
    }
  }

  function bindPusherChannels(channelName: string): void {
    if (pusherChannel === null && (chatRoomMessages.length > 0 || chatRoom.group_chat_room === true)) {
      if (pusher === null) {
        setPusher(
          new Pusher(config.pusher.key, {
            cluster: 'eu',
            authEndpoint: `${config.api.url}broadcasting/auth`,
          }),
        );
      }

      if (pusher) {
        const channel = pusher.subscribe(channelName);

        channel.unbind_all();

        channel.bind('chat.messageSent', (data: ChatMessages) => {
          addNewMessage(data);
        });

        channel.bind('pusher:subscription_succeeded', function (members: ChatMember[]) {
          console.log('successfully subscribed to pusher');
        });

        channel.bind('pusher:member_added', function (member: ChatMember) {
          console.log('new member joined: ' + member.nickname);
        });

        setPusherChannel({
          channel: channel,
          eventsBound: true,
        });
      }
    } else if (chatRoomMessages.length === 0 && chatRoom.group_chat_room === false) {
      console.log('pusher init skipped => chat not used');
    } else if (channelName !== pusherChannel?.channel.name) {
      console.log('starting unsubsribe from ' + pusherChannel.channel.name);
      pusherChannel.channel.unbind_all();
      pusherChannel.channe.unsubscribe();
      setPusherChannel(null);
    } else {
      console.log(pusherChannel.channel.name);
      console.log('pusher already initiated');
    }
  }

  const handleSubmit = (evt: SyntheticEvent) => {
    if (message.value.length > 0) {
      const values = {
        message: message.value,
        recipient_id: chatRoom.owner.id,
        recipient_type: 'guest',
      };

      postMessage(chatRoom.submit_url, values).then((res: AxiosResponse<ChatMessages>) => {
        addNewMessage(res.data);
      });
    }

    message.value = '';
    evt.preventDefault();
  };

  window.requestAnimationFrame(function () {
    scrollToBottom();
  });

  bindPusherChannels(chatRoom.channel);

  return (
    <div>
      {/* <div className="hero-head">
        <header className="hero is-link is-bold">
          <div className="hero-body">
            <div className="container">
              <p className="title">Chat: {chatRoom.title}</p>
              <p className="subtitle">Chat room owned by {chatRoom.owner?.nickname}</p>
            </div>
          </div>
        </header>
      </div> */}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body chat-body" style={{ overflowX: 'scroll' }} id="chat-body">
              {chatRoomMessages?.map((chatMessage: ChatMessages) => {
                return (
                  <ChatRoomMessageComponent
                    message={chatMessage}
                    key={chatMessage.id}
                    displayLeft={
                      chatRoom.current_authenticated_user === chatMessage.sender?.id
                    }></ChatRoomMessageComponent>
                );
              })}

              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>

        <div className="col-12">
          {chatRoom.submit_url ? (
            <form onSubmit={handleSubmit} className="form-inline w-100 chat-input-form">
              <input
                className="form-control mr-2 chat-input"
                name="chatInput"
                type="text"
                placeholder={integration.payload.new_message_html}
                ref={(c) => (message = c)}
              />
              <button className="btn btn-primary flex-fill" dangerouslySetInnerHTML={{ __html: integration.payload.send_button_html }} />
            </form>
          ) : (
            <div>{integration.translation.no_answer_possible}</div>
          )}
        </div>
      </div>
    </div>
  );
}
