import { Form as FormikForm, Formik } from 'formik';
import _ from 'lodash';
import React, { ReactElement, ReactNode, useState } from 'react';
import * as Yup from 'yup';
import { FormFieldGroup } from '../../entities/form-field-group.entity';
import FormFieldType from '../../entities/form-field-type.enum';
import { FormFieldValue } from '../../entities/form-field-value.entity';
import FormField from '../../entities/form-field.entity';
import FormIntegration from '../../entities/form-integration.entity';
import Form from '../../entities/form.entity';
import { ErrorResponse, IntegrationsContainer } from '../../stores/integration.store';
import CheckboxGroup from './fields/checkbox-group/checkbox-group';
import File from './fields/file';
import HTML from './fields/html';
import Input from './fields/input';
import Radio from './fields/radio';
import Range from './fields/range';
import Select from './fields/select';
import Textarea from './fields/textarea';
import { Pagination } from './pagination.component';

export interface Props {
  integration: FormIntegration;
}

export function HoFormComponent({ integration }: Props): ReactElement {
  const { payload } = integration;
  const {
    postIntegration,
    postResponse,
    error: postError,
    resetPostError,
  } = IntegrationsContainer.useContainer();
  const [allValues, setAllValues] = useState<Record<string, FormFieldValue>>({});
  const [groupIndexToShow, setGroupIndexToShow] = useState<number>(0);

  const groups = payload.form_field_groups;

  const changeStep = (amount: number): void => {
    const newStep = groupIndexToShow + amount;

    if (newStep < 0) {
      return;
    }
    setGroupIndexToShow(newStep);
  };

  const onSubmit = (
    values: { [key: string]: any },
    { setSubmitting }: { setSubmitting: (submitting: boolean) => void },
  ): void => {
    const newValues = { ...allValues, ...values };
    setAllValues(newValues);

    if (
      integration.payload.paginate_groups &&
      groupIndexToShow < integration.payload.form_field_groups.length - 1
    ) {
      changeStep(1);
      return;
    }

    resetPostError();

    let submitUrl = integration.submit_url;
    if (integration.type === 'booking') {
      const bookingSource = new URLSearchParams(window.location.search).get('bsrc');

      if (bookingSource) {
        submitUrl = submitUrl + '?booking_source=' + bookingSource;
      }
    }

    if (integration.type === 'registration') {
      const registrationSource = new URLSearchParams(window.location.search).get('rsrc');

      if (registrationSource) {
        submitUrl = submitUrl + '?registration_source=' + registrationSource;
      }
    }

    postIntegration(submitUrl, newValues).finally(() => {
      setSubmitting(false);
    });
  };

  const initialValuesForFields = (fields: FormField[]): { [name: string]: any } => {
    return fields.reduce((obj: { [key: string]: any }, field: FormField) => {
      return {
        ...obj,
        [field.name]: allValues[field.name] || field.initial_value || '',
      };
    }, {});
  };
  const initialValues = groups.map((group) => initialValuesForFields(group.fields));

  const getFieldValidation = (field: FormField, obj = {}): {} => {
    let validation;

    switch (field.type) {
      case FormFieldType.email:
        validation = Yup.string().email(integration.translation.valid_email);
        break;
      case FormFieldType.checkbox:
        validation = Yup.array();
        break;
      case FormFieldType.date:
        validation = Yup.date();
        break;
      case FormFieldType.password:
        validation = Yup.string().min(8, integration.translation.password_length);
        break;
      default:
        validation = Yup.mixed();
        break;
    }

    return {
      ...obj,
      [field.name]: field.required ? validation.required(integration.translation.field_required) : validation,
    };
  };

  const validationSchema = (payload: Form): Yup.ObjectSchema<object>[] => {
    if (payload.paginate_groups) {
      return payload.form_field_groups.map((group) =>
        Yup.object().shape(
          group.fields.reduce(
            (obj: { [key: string]: any }, field: FormField) => getFieldValidation(field, obj),
            {},
          ),
        ),
      );
    } else {
      const cominedFields = _.flatten(payload.form_field_groups.map((group) => group.fields));
      const validation = Yup.object().shape(
        cominedFields.reduce(
          (obj: { [key: string]: any }, field: FormField) => getFieldValidation(field, obj),
          {},
        ),
      );

      return [validation];
    }
  };

  const getServerFormFieldErrors = (groups: FormFieldGroup[], error: ErrorResponse): string[] => {
    if (!error?.payload) {
      return;
    }

    const formFieldNameLabelMapping = _.mapValues(
      _.keyBy(
        _.flatten(groups.map((group) => group.fields)).map((field) => ({
          name: field.name,
          label: field.label,
        })),
        'name',
      ),
      'label',
    );
    return Object.entries(error.payload).map(([key, value]: [string, { errors: string[] }]) => {
      return formFieldNameLabelMapping[key] + ': ' + value.errors.toString();
    });
  };

  if (postResponse) {
    if (integration.success_page_url) {
      window.location.href = integration.success_page_url;
    } else {
      return (
          <div className="ho-message-container ho-success-message-container">
            <span className="ho-success ho-form-success">{integration.payload.success_message}</span>
          </div>
      );
    }
  }

  //show password field if booking form without login is used and user has already an account
  let errorMessage: string = null;
  if (
    postError &&
    postError?.message?.error === 'ho-password-field missing' &&
    errorMessage !== integration.translation.provide_existing_password
  ) {
    const lastGroupId: number = groups.length - 1;

    groups[lastGroupId].fields.push({
      css_class: undefined,
      helptext: undefined,
      initial_value: undefined,
      input_css_class: undefined,
      label: 'Password',
      label_css_class: undefined,
      name: 'ho-password-field',
      placeholder: undefined,
      required: true,
      type: FormFieldType.password,
    });

    errorMessage = integration.translation.provide_existing_password;
  } else if (postError?.message?.error) {
    errorMessage = postError?.message?.error;
  }

  return (
    <div className={`ho-form ${payload.css_class || ''} ${postError ? 'submission-fail' : ''}`}>
      <Formik
        enableReinitialize
        initialValues={
          payload.paginate_groups
            ? initialValues[groupIndexToShow]
            : Object.assign({}, ...initialValues)
        }
        validationSchema={validationSchema(payload)[groupIndexToShow]}
        onSubmit={onSubmit}
        className="ho-form">
        {(formik): ReactNode => (
          <FormikForm noValidate id={'ho-form-id-' + (integration.uuid ?? payload.id)}>
            {groups.map((group: FormFieldGroup, index: number) => (
              <div key={group.uuid}>
                {(!payload.paginate_groups || groupIndexToShow === index) && (
                  <div
                    className={`ho-field-group ${group.css_class ? group.css_class : ''}`}
                    key={group.uuid}>
                    <h3>{group.title}</h3>

                    {group.fields.map((hoField: FormField) => {
                      switch (hoField.type) {
                        case FormFieldType.textarea:
                          return <Textarea key={hoField.name} hoField={hoField} formik={formik} />;
                        case FormFieldType.select:
                          return <Select key={hoField.name} hoField={hoField} formik={formik} />;
                        case FormFieldType.checkbox:
                          return (
                            <CheckboxGroup key={hoField.name} hoField={hoField} formik={formik}></CheckboxGroup>
                          );
                        case FormFieldType.radio:
                          return <Radio key={hoField.name} hoField={hoField} formik={formik} />;
                        case FormFieldType.file:
                          return <File key={hoField.name} hoField={hoField} formik={formik} />;
                        case FormFieldType.range:
                          return <Range key={hoField.name} hoField={hoField} formik={formik} />;
                        case FormFieldType.html:
                          return <HTML key={hoField.name} hoField={hoField} formik={formik} />;
                        default:
                          return <Input key={hoField.name} hoField={hoField} formik={formik} />;
                      }
                    })}
                    {payload.paginate_groups && (
                      <Pagination
                        prev={() => changeStep(-1)}
                        currentIndex={groupIndexToShow}
                        length={groups.length}></Pagination>
                    )}
                  </div>
                )}
              </div>
            ))}
            {postError && (
              <>
                {getServerFormFieldErrors(groups, postError)?.map((error) => (
                  <div key={error} className="ho-error-message">
                    {error}
                  </div>
                ))}

                <div className="ho-error-message">
                  {errorMessage || integration.translation.cannot_submit_form}
                </div>
              </>
            )}

            {(!payload.paginate_groups || groupIndexToShow === groups.length - 1) && (
              <button
                className={`ho-submit ${payload.submit_css_class ? payload.submit_css_class : ''}`}
                type="submit"
                disabled={formik.isSubmitting}>
                {payload.submit_text ? payload.submit_text : 'Submit'}
              </button>
            )}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}
