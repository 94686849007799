import React, { ReactElement, SyntheticEvent, useReducer, useState } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import ChatIntegration from '../../entities/chat-integration.entity';
import ChatRoom, { ChatMember } from '../../entities/chat-room.entity';
import { ChatRoomsContainer } from '../../stores/chat.store';
import { ChatRoomComponent } from './chat-room.component';
import { AxiosResponse } from 'axios';
import Pusher from 'pusher-js';
import config from '../../config/config';

export interface ChatComponentProps {
  integration: ChatIntegration;
}

export function ChatComponent({ integration }: ChatComponentProps): ReactElement {
  const { error } = IntegrationsContainer.useContainer();
  const { useGetChatRooms, useGetSingleChatRoom } = ChatRoomsContainer.useContainer();

  const [chatRooms, setChatRooms] = useState(null);
  const [activeChatroom, setActiveChatroom] = useState(null);
  const [pusherChannel, setPusherChannel] = useState(null);
  const [pusher, setPusher] = useState(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const GetChatRooms = (): void => {
    useGetChatRooms(integration.payload.rooms_url).then((res: AxiosResponse<ChatRoom[]>) => {
      setChatRooms(res.data);
    });
  };

  const GetSingleChatRoom = (): void => {
    useGetSingleChatRoom((integration.payload.rooms_url + '/' + integration.payload.group_chat_room_id), false).then((res: AxiosResponse<ChatRoom>) => {
      setChatRooms([res.data]);
    });
  };

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  if (!chatRooms) {
    if(integration.payload.group_chat_room_id) {
      GetSingleChatRoom();
    } else {
      GetChatRooms();
    }
    return <div>{integration.translation.loading_chatroom}</div>;
  }

  if (!activeChatroom) {
    if (chatRooms.length === 1 && integration.payload.single_chat === true) {
      setActiveChatroom(chatRooms[0]);
    }

    if (chatRooms.length > 0 && integration.payload.single_chat === false) {
      chatRooms[0].unread = false;
      setActiveChatroom(chatRooms[0]);
    }

    if (chatRooms.length === 0) {
      return <div>{integration.translation.no_chatroom_available}</div>;
    }

    return <div>{integration.translation.unsupported_configuration}</div>;
  }

  function addUsedRoom(room: ChatRoom): void {
    const index = chatRooms.findIndex((r: ChatRoom) => r.id === room.id);

    if (index !== -1) {
      chatRooms.splice(index, 1);
    }

    if (room.id === activeChatroom.id) {
      room.unread = false;
    }

    chatRooms.unshift(room);
    setChatRooms(chatRooms);
    forceUpdate();
  }

  function bindPusherChannels(): void {
    if (pusherChannel === null && integration.payload.single_chat === false) {
      if (pusher === null) {
        setPusher(
          new Pusher(config.pusher.key, {
            cluster: 'eu',
            authEndpoint: `${config.api.url}broadcasting/auth`,
          }),
        );
      }

      if (pusher) {
        console.log('pusher subscribed to rooms channel');

        const channel = pusher.subscribe(integration.payload.rooms_used_channel);

        channel.unbind_all();

        channel.bind('chat.roomUsed', (data: ChatRoom) => {
          addUsedRoom(data);
        });

        channel.bind('pusher:subscription_succeeded', function (members: ChatMember[]) {
          console.log('successfully subscribed to pusher rooms channel');
        });

        channel.bind('pusher:member_added', function (member: ChatMember) {
          console.log('new member joined: ' + member.nickname);
        });

        setPusherChannel({
          channel: channel,
          eventsBound: true,
        });
      }
    } else if (pusher && pusherChannel) {
      console.log(pusherChannel.channel.name);
      console.log('pusher already initiated');
    }
  }

  const getReadableDate = (dateString: string): string | number | Date => {
    const now = new Date();
    const date = new Date(dateString);

    if (date.getDay() === now.getDay()) {
      return date.getHours() + ':' + date.getMinutes();
    } else {
      return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();
    }
  };

  const activateSelectedChatroom = (evt: SyntheticEvent, chatRoom: ChatRoom) => {
    evt.preventDefault();
    chatRoom.unread = false;
    setActiveChatroom(chatRoom);
  };

  bindPusherChannels();

  return (
    <div className="chat chat-container">
      <div className="row">
        <div className={` ${integration.payload.single_chat ? 'd-none' : 'col-sm-3'} `}>
          <ul className="list-group list-group-flush">
            {chatRooms.map((chatRoom: ChatRoom) => {
              return (
                <a
                  className={`list-group-item list-group-item-action ${
                    activeChatroom.id === chatRoom.id ? 'active' : ''
                  } ${chatRoom.unread ? 'list-group-item-info' : ''} 
                  `}
                  key={chatRoom.id}
                  onClick={(e) => activateSelectedChatroom(e, chatRoom)}>
                  <div className="d-flex w-100 justify-content-between">
                    <div className="mb-1 chatroom-list-owner-nickname">
                      {chatRoom.owner?.nickname}
                    </div>
                    <small className="chatroom-list-date">
                      {getReadableDate(chatRoom.last_message_sent_at)}
                    </small>
                  </div>
                  <p className="mb-1 chatroom-list-last-message">{chatRoom.last_message}</p>
                  <small className="chatroom-list-associated-title">
                    {chatRoom.associated?.title}
                  </small>
                </a>
              );
            })}
          </ul>
        </div>
        <div className={` ${integration.payload.single_chat ? 'col-sm-12' : 'col-sm-9'} `}>
          <ChatRoomComponent
            chatRoom={activeChatroom}
            integration={integration}
            key={activeChatroom.id}
          />
        </div>
      </div>
    </div>
  );
}
