import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import config from '../config/config';

interface HookInterface {
  getCSRFCookie: () => Promise<AxiosResponse<void>>;
  error: string;
}

const useAuth = (): HookInterface => {
  const [error, setError] = useState<string>(null);

  const getCSRFCookie = async (): Promise<AxiosResponse<void>> => {
    try {
      let fqdn = `${config.api.url}u/csrf-cookie`;

      if (config.useCustomLocale === true) {
        fqdn = fqdn + `?locale=${config.locale}`;
        console.log('Locale forced to: ' + config.locale);
      }

      return await axios.get(fqdn);
    } catch (error) {
      setError(error.response?.data?.data?.message);
      throw error;
    }
  };

  return { error, getCSRFCookie };
};

export const AuthContainer = createContainer(useAuth);
