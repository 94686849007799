import React, { ReactElement } from 'react';
import { CheckboxProvider } from './checkbox-context';
import Checkbox from './checkbox';
import {useField, ErrorMessage, FormikProps, getIn} from 'formik';
import FormField from '../../../../entities/form-field.entity';

interface Props {
  hoField: FormField;
  formik: FormikProps<any>
}

type CheckboxGroup = React.FC<Props> & {
  Checkbox: typeof Checkbox;
};

const CheckboxGroup: CheckboxGroup = ({ hoField, formik }: Props) => {
  const [field, meta, helpers] = useField<string[]>(hoField.name);
  let singleCheckbox: boolean;

  if(hoField.choices !== null && hoField.choices.length > 0) {
      singleCheckbox = false;
  } else {
      singleCheckbox = true;
  }

  return (
    <fieldset className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
        {singleCheckbox && (
            <React.Fragment>
                <CheckboxProvider value={{ field, helpers, formField: hoField }}>
                    <CheckboxGroup.Checkbox
                        className={`ho-check-field ho-single-check-field ${hoField.css_class ? hoField.css_class : ''}`}
                        key={field.name}
                        label={hoField.label}
                        value="true"
                        useHtml={true}
                    />
                </CheckboxProvider>
            </React.Fragment>
        )}



      {!singleCheckbox && (
          <React.Fragment>
              <legend className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`} dangerouslySetInnerHTML={{__html: hoField.required ? hoField.label + '*' : hoField.label}}>
              </legend>

              <CheckboxProvider value={{ field, helpers, formField: hoField }}>
                  {hoField.choices.map((choice) => (
                      <CheckboxGroup.Checkbox
                          className={`ho-check-field ${hoField.css_class ? hoField.css_class : ''}`}
                          key={choice.value}
                          label={choice.label}
                          value={choice.value}
                          useHtml={false}
                      />
                  ))}
              </CheckboxProvider>
          </React.Fragment>
      )}

      <div className="ho-help-text">{hoField.helptext}</div>
      <ErrorMessage name={hoField.name} component="div" className="ho-field-error"></ErrorMessage>
    </fieldset>
  );
};

CheckboxGroup.Checkbox = Checkbox;
export default CheckboxGroup;
