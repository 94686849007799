import { Form as FormikForm, Formik } from 'formik';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import LoginIntegration from '../../entities/login-integration.entity';
import { IntegrationsContainer } from '../../stores/integration.store';
import Email from './fields/email';
import Password from './fields/password';
import PasswordConfirmation from './fields/password-confirmation';
import { FormFieldValue } from '../../entities/form-field-value.entity';
import { LoginTranslationEntity } from '../../entities/login.translation.entity';
import { LoginContainer } from '../../stores/login.store';
import { AxiosResponse } from 'axios';

export interface Props {
  integration: LoginIntegration;
}

type UiState = 'login' | 'password-forgot' | 'password-reset';

export function LoginComponent({ integration }: Props): ReactElement {
  const [email, setEmail] = useState<string>();
  const [token, setToken] = useState<string>();
  const [uiState, setUiState] = useState<UiState>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const { postIntegration } = IntegrationsContainer.useContainer();
  const { postLogin, postResponse, error: postError } = LoginContainer.useContainer();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hoToken = urlParams.get('ho-token');
    const email = urlParams.get('ho-email-field');
    setEmail(email);
    setToken(hoToken);
    setUiState(hoToken ? 'password-reset' : 'login');
  }, []);

  const buttonLabelForState = (uiState: UiState): string => {
    switch (uiState) {
      case 'login':
        return integration.translation.login_button_label;
      case 'password-forgot':
        return integration.translation.request_password_reset_button_label;
      case 'password-reset':
        return integration.translation.reset_password_button_label;
      default:
        break;
    }
  };

  const onSubmit = (
    values: Record<string, FormFieldValue>,
    { setSubmitting }: { setSubmitting: (submitting: boolean) => void },
  ): void => {
    let url = integration.submit_url;

    if (uiState === 'password-forgot') {
      url = integration.request_password_reset_submit_url;
    } else if (uiState === 'password-reset') {
      url = integration.password_reset_submit_url;
      values = { ...values, 'ho-token': token };
    }

    if (uiState === 'login') {
      postLogin(url, values).then((response: AxiosResponse<{ url: string }>) => {
        if (uiState === 'login') {
          const urlParams = new URLSearchParams(window.location.search);

          if (urlParams.has('intendedUrl')) {
            window.location.replace(urlParams.get('intendedUrl'));
            return;
          }

          if (response?.data?.url) {
            window.location.href = response.data.url;
          } else {
            window.location.href = '/';
          }
        }
      });
    } else {
      postIntegration(url, values).then(() => {
        setShowSuccessMessage(true);
        setSubmitting(false);
      });
    }

    setSubmitting(false);
  };

  if (postResponse || showSuccessMessage) {
    if (uiState === 'password-forgot') {
      return (
        <div className="ho-login-widget">
          <span className="ho-success ho-form-success">
            {integration.translation.password_reset_request_successful}
          </span>
        </div>
      );
    }

    if (uiState === 'password-reset') {
      return (
        <div className="ho-login-widget">
          <span className="ho-success ho-form-success">{integration.translation.password_reset_successful}</span>
        </div>
      );
    }
  }

  const redirectToSsoProvider = (e: SyntheticEvent) => {
    window.location.href = integration.payload.sso_url;
  };

  return (
    <div className="ho-login-widget">
      <Formik
        initialValues={{
          'ho-email-field': email || '',
          'ho-password-field': '',
        }}
        onSubmit={onSubmit}
        autocomplete="on">
        <FormikForm className="ho-form ho-login-form">
          <Email labelText={integration.translation.email_input_label} />

          {(uiState === 'login' || uiState === 'password-reset') && (
            <Password labelText={integration.translation.password_input_label} />
          )}

          {uiState === 'password-reset' && (
            <PasswordConfirmation labelText={integration.translation.password_repeat_input_label} />
          )}

          <button className="ho-button ho-login-button" type="submit">
            {buttonLabelForState(uiState)}
          </button>

          {uiState === 'login' && (
            <button
              type="button"
              onClick={() => setUiState('password-forgot')}
              className="ho-button ho-password-forgot-button">
              {integration.translation.forgot_password_button_label}
            </button>
          )}
          {uiState === 'password-forgot' && (
            <button
              type="button"
              onClick={() => setUiState('login')}
              className="ho-button ho-password-forgot-button">
              {integration.translation.back_button_label}
            </button>
          )}

          {postError && <span className="ho-error-message">{postError.message?.error}</span>}
        </FormikForm>
      </Formik>

      {integration.payload.sso_url && uiState === 'login' && (
        <button onClick={redirectToSsoProvider} className="ho-button ho-sso-login-button">
          {integration.translation.sso_button_label}
        </button>
      )}
    </div>
  );
}
