import React, { FunctionComponent, ReactElement } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from '../config/config';

let ErrorBoundary: React.ComponentType;

if (config.useBugRepoter) {
  Bugsnag.start({
    apiKey: '1641dbe715610bfa55dcfb83aafd089f',
    plugins: [new BugsnagPluginReact()],
  });
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}
interface Props {
  children: ReactElement;
}

const BugReporter: FunctionComponent<Props> = (props: Props) => {
  if (config.useBugRepoter) {
    return <ErrorBoundary>{props.children}</ErrorBoundary>;
  } else {
    return <>{props.children}</>;
  }
};

export default BugReporter;
