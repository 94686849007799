import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { ErrorResponse } from './integration.store';
import { FormFieldValue } from '../entities/form-field-value.entity';
import ChangeLanguageIntegrationResponse from '../entities/change-language-integration-response.entity';

interface ChangeLanguageInterface {
  postChangeLanguage: (
    url: string,
    values: Record<string, FormFieldValue>,
  ) => Promise<AxiosResponse<ChangeLanguageIntegrationResponse>>;
  postResponse: AxiosResponse<void>;
  error: ErrorResponse;
  resetPostError: () => void;
}

const useChangeLanguage = (): ChangeLanguageInterface => {
  const [postResponse, setPostResponse] = useState(null);
  const [error, setError] = useState<ErrorResponse>(null);

  const resetPostError = (): void => {
    setError(null);
  };

  const postChangeLanguage = async (
    url: string,
    values: Record<string, FormFieldValue>,
  ): Promise<AxiosResponse<ChangeLanguageIntegrationResponse>> => {
    try {
      const data = await axios.post(url, { payload: values });
      setPostResponse(data.data);
      return data.data;
    } catch (error) {
      setError(error.response?.data?.data);
      throw error;
    }
  };

  return {
    postChangeLanguage,
    postResponse,
    error,
    resetPostError,
  };
};

export const ChangeLanguageContainer = createContainer(useChangeLanguage);
