/* eslint-disable react/no-array-index-key */
import {ErrorMessage, Field, getIn} from 'formik';
import * as React from 'react';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const Radio: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
  formik,
}: DefaultFieldComponentProps) => {
  return (
    <div className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
      <p className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}>
        {hoField.label}
        {hoField.required &&
            <span  className="ho-required-star">*</span>
        }
      </p>
      <Field
        component="div"
        className={`ho-radio-field ${hoField.css_class ? hoField.css_class : ''}`}
        id={`radio-id-${hoField.name}`}
        name={hoField.name}>
        {hoField.choices.map((choice: { label: string; value: string }, index: number) => (
          <span className="ho-radio-option" key={index}>
            <input
              className="ho-radio-input"
              type={hoField.type}
              name={hoField.name}
              value={choice.value}
              id={`radio-id-${hoField.name}-${index}`}
              placeholder={hoField.placeholder}
            />
            <label className="ho-radio-label" htmlFor={`radio-id-${hoField.name}-${index}`}>
              {choice.label}
            </label>
          </span>
        ))}
      </Field>
      <span className="ho-help-text">{hoField.helptext}</span>

      <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
    </div>
  );
};

export default Radio;
