import * as React from 'react';
import { Field, ErrorMessage } from 'formik';

const Email: React.FunctionComponent<{ labelText: string }> = (labelText) => {
  return (
    <div className="ho-field">
      <label className="ho-label" htmlFor="ho-email-field">
          {labelText.labelText}
      </label>
      <Field
        className="ho-input"
        name="ho-email-field"
        type="email"
        placeholder={labelText.labelText}
        autoComplete="email"
        required
      />
      <ErrorMessage name="ho-email-field" component="div" className="ho-field-error" />
    </div>
  );
};

export default Email;
