import * as React from 'react';
import { Field, ErrorMessage } from 'formik';

const Password: React.FunctionComponent<{labelText: string}> = (
    labelText
) => {
  return (
    <div className="ho-field">
      <label className="ho-label" htmlFor="ho-password-field">
          {labelText.labelText}
      </label>
      <Field
        className="ho-input"
        name="ho-password-field"
        type="password"
        placeholder={labelText.labelText}
        autoComplete="current-password"
        required
      />
      <ErrorMessage name="ho-password-field" component="div" className="ho-field-error" />
    </div>
  );
};
export default Password;
