import {ErrorMessage, Field, FormikProps, getIn} from 'formik';
import * as React from 'react';
import FormField from '../../../entities/form-field.entity';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const Range: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
  formik,
}: DefaultFieldComponentProps) => {
  return (
    <div className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
      <label
        className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}
        htmlFor={hoField.name}>
        {hoField.label}
        {hoField.required &&
            <span className="ho-required-star">*</span>
        }
      </label>
      <div>
        <span>{hoField.min}</span>
        <Field
          className="ho-input "
          min={hoField.min}
          type={hoField.type}
          name={hoField.name}
          required={hoField.required}
          placeholder={hoField.placeholder}
        />
        <span>{hoField.max}</span>
      </div>
      <span>{formik.values[hoField.name]}</span>
      <span className="ho-help-text">{hoField.helptext}</span>

      <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
    </div>
  );
};

export default Range;
