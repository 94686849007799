import React, { ReactElement, SyntheticEvent, useReducer, useState } from 'react';
import { MeetingContainer } from '../../stores/meeting.store';
import MeetingIntegration, {
  MeetingSlot,
  MeetingSlotStatus,
} from '../../entities/meeting-integration.entity';
import { AxiosResponse } from 'axios';

export interface MeetingComponentProps {
  integration: MeetingIntegration;
}

export function MeetingComponent({ integration }: MeetingComponentProps): ReactElement {
  const { error, postBooking } = MeetingContainer.useContainer();

  const [integrationState, setIntegrationState] = useState(integration);
  const [booked, setBooked] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  let currentDay: string = null;
  let currentDuration: bigint = null;
  let slotCount = 1;

  const submitBooking = (e: SyntheticEvent, slot: MeetingSlot) => {
    postBooking(integrationState.submit_url, {
      'ho-express-booking': true,
      'ho-meeting-timeslot': slot.id,
    }).then((res: AxiosResponse<void>) => {
      forceUpdate();
      setBooked(true);
    });
  };

  const submitCancellation = (e: SyntheticEvent, slot: MeetingSlot) => {
    postBooking(integrationState.submit_url, {
      'ho-meeting-cancellation': true,
      'ho-meeting-timeslot': slot.id,
    }).then((res: AxiosResponse<void>) => {
      forceUpdate();
      setBooked(false);
      setCancelled(true);
    });
  };

  const detectNewDay = (slot: MeetingSlot) => {
    if (currentDay !== slot.meeting_start_readable_date) {
      currentDay = slot.meeting_start_readable_date;
      slotCount = 1;
      return true;
    } else {
      slotCount++;
    }

    return false;
  };

  const detectNewDuration = (slot: MeetingSlot) => {
    if (currentDuration !== slot.meeting_duration) {
      currentDuration = slot.meeting_duration;
      return true;
    }

    return false;
  };

  const breakLine = (): boolean => {
    return slotCount % 3 === 0;
  };

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  if (booked) {
    return (
      <div className="ho-message-container">
        <span className="ho-success ho-form-success">
          {integrationState.payload.success_message_text}
        </span>
      </div>
    );
  }

  if (cancelled) {
    return (
      <div className="ho-message-container">
        <span className="ho-success ho-form-success ho-booking-cancelled">
          {integrationState.payload.cancel_booking_confirmation_text}
        </span>
      </div>
    );
  }

  const getLocalizedDate = (date: string) => {
    return new Intl.DateTimeFormat(integrationState.payload.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneName: 'short',
    }).format(new Date(date));
  };

  const getLocalizedTime = (time: string) => {
    return new Intl.DateTimeFormat(integrationState.payload.locale, {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).format(new Date(time));
  };

  if (integrationState.payload.current_authenticated_guests_booking === true) {
    const bookedText = integrationState.payload.booked_text
      .replace('<<DATE>>', getLocalizedDate(integration.payload.booked_slot.meeting_start))
      .replace('<<START>>', getLocalizedTime(integration.payload.booked_slot.meeting_start))
      .replace('<<ENDE>>', getLocalizedTime(integration.payload.booked_slot.meeting_end));

    return (
      <div className="meeting-already-booked">
        {bookedText}
        <br />
        <button
          className={integration.payload.slot_button_classes}
          onClick={(e) => submitCancellation(e, integration.payload.booked_slot)}>
          {integrationState.payload.cancel_booking_button_text}
        </button>
      </div>
    );
  }

  if (integrationState.payload.slots?.length === 0) {
    return <div className="meeting-container">Sorry, no slots available!</div>;
  }

  return (
    <div className="meeting-container">
      {integrationState.payload.slots?.map((slot: MeetingSlot) => {
        return (
          <div key={slot.id}>
            {detectNewDay(slot) && (
              <div className={integration.payload.slot_day_outer_classes}>
                <h5 className={'ho-current-slot-day-text'}>
                  {integration.payload.current_slot_day_text} {getLocalizedDate(slot.meeting_start)}
                </h5>
              </div>
            )}

            {false && detectNewDuration(slot) && (
                <div className={integration.payload.slot_day_outer_classes}>
                  <h5 className={'ho-current-slot-day-text'}>
                    {slot.meeting_duration}
                  </h5>
                </div>
            )}

            <div className={integration.payload.slot_button_outer_classes}>
              <button
                className={
                  slot.has_free_guest_slots
                    ? integration.payload.slot_button_classes
                    : integrationState.payload.slot_button_booked_classes
                }
                style={{
                  cursor:
                    !slot.has_free_guest_slots || slot.slot_status != MeetingSlotStatus.free
                      ? 'not-allowed'
                      : 'pointer',
                }}
                disabled={!slot.has_free_guest_slots || slot.slot_status != MeetingSlotStatus.free}
                onClick={(e) => submitBooking(e, slot)}>
                {getLocalizedTime(slot.meeting_start)}-{getLocalizedTime(slot.meeting_end)}
              </button>
            </div>

            {breakLine() && <p></p>}
          </div>
        );
      })}
    </div>
  );
}
