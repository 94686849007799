import React, { ReactElement } from 'react';
import { IntegrationsContainer } from '../../stores/integration.store';
import LogoutIntegration from "../../entities/logout-integration.entity";

export interface LogoutComponentProps {
  integration: LogoutIntegration;
}

export function LogoutComponent({ integration }: LogoutComponentProps): ReactElement {
  const { postIntegration, error } = IntegrationsContainer.useContainer();

  const onLogout = (): void => {
    postIntegration(integration.submit_url, {}).then(() => {
      window.location.href = '/';
    });
  };

  if (error) {
    return <div>{error.message?.error}</div>;
  }

  return (
    <button type="button" onClick={onLogout} className="ho-button ho-logout-button">
      {integration.translation.btn_text}
    </button>
  );
}
