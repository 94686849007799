import React, { ReactElement } from 'react';

export interface Props {
  length: number;
  currentIndex: number;
  prev: () => void;
}

export const Pagination = ({ length, currentIndex, prev }: Props): ReactElement => {
  return (
    <div
      className="ho-pagination-container"
      style={{ display: 'flex', justifyContent: 'space-between' }}>
      {currentIndex > 0 ? (
        <button type="button" className="ho-previous-button" onClick={() => prev()}>
          Previous
        </button>
      ) : (
        <span />
      )}
      {currentIndex < length - 1 && (
        <button type="submit" className="ho-next-button">
          Next
        </button>
      )}
    </div>
  );
};
