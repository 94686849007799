/* eslint-disable react/no-danger */
import * as React from 'react';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const HTML: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
}: DefaultFieldComponentProps) => {
  return (
    <div
      className={`ho-content-field ${hoField.css_class ? hoField.css_class : ''}`}
      dangerouslySetInnerHTML={{ __html: hoField.initial_value.toString() }}
    />
  );
};

export default HTML;
