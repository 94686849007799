import {ErrorMessage, Field, FormikProps, getIn} from 'formik';
import * as React from 'react';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';
import FormFieldType from '../../../entities/form-field-type.enum';

const Input: React.FunctionComponent<DefaultFieldComponentProps> = ({
    hoField,
    formik
}: DefaultFieldComponentProps) => {
  let autocomplete: string;

  if (hoField.type === FormFieldType.password) {
    autocomplete = 'new-password';
  } else if (hoField.type === FormFieldType.email) {
    autocomplete = 'username';
  }

  return (
    <div className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
      <label
        className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}
        htmlFor={hoField.name}
        dangerouslySetInnerHTML={{__html: hoField.required ? hoField.label + '<span class="ho-required-star">*</span>' : hoField.label}}>
        </label>
      <Field
        className={`ho-input ${hoField.input_css_class ? hoField.input_css_class : ''}`}
        type={hoField.type}
        name={hoField.name}
        required={hoField.required}
        placeholder={hoField.placeholder}
        autoComplete={autocomplete}
      />
      <span className="ho-help-text">{hoField.helptext}</span>

      <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
    </div>
  );
};

export default Input;
