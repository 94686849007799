import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components/app';
import { AuthContainer } from './stores/auth.store';
import { IntegrationsContainer } from './stores/integration.store';
import BugReporter from './utils/bug-reporter';
import Integration from './entities/integration.entity';
import Config from './entities/config.entity';

// todo: use an options object with defined values instead of containerId
const initAndRender = (
  uuid: string,
  containerId: string = null,
  integration: Integration = null,
): void => {
  const createdDivElement = document.createElement('DIV');

  if (containerId === null) {
    const script = document.getElementById(uuid);
    script.parentNode.insertBefore(createdDivElement, script.nextSibling);
  } else {
    //its only undocumented if initAndRender is called directly, initWithIntegration documents it implicitly
    if (integration === null) {
      console.warn(
        'using undocumented containerId feature. this will not work in a future version',
      );
    }

    const elem = document.getElementById(containerId);
    elem.appendChild(createdDivElement);
  }
  const config: Config = {
    uuid,
    container_id: containerId,
    integration: integration,
  };

  ReactDOM.render(
    <BugReporter>
      <AuthContainer.Provider>
        <IntegrationsContainer.Provider>
          <App uuid={uuid} config={config} />
        </IntegrationsContainer.Provider>
      </AuthContainer.Provider>
    </BugReporter>,
    createdDivElement,
  );
};

const initWithIntegration = (integration: Integration, containerId: string = null): void => {
  return initAndRender(integration.uuid, containerId, integration);
};

const createHoComponent = (uuid: string): void => {
  console.error('Method ho.createHoComponent() is deprecated. Use ho.init() instead');
  return initAndRender(uuid);
};

const initWithConfig = (config: Config): void => {
  const createdDivElement = document.createElement('DIV');

  if (config.container_id) {
    const elem = document.getElementById(config.container_id);
    elem.appendChild(createdDivElement);
  } else {
    const script = document.getElementById(config.uuid);
    script.parentNode.insertBefore(createdDivElement, script.nextSibling);
  }

  ReactDOM.render(
    <BugReporter>
      <AuthContainer.Provider>
        <IntegrationsContainer.Provider>
          <App uuid={config.uuid} config={config} />
        </IntegrationsContainer.Provider>
      </AuthContainer.Provider>
    </BugReporter>,
    createdDivElement,
  );
};
const ho = {
  createHoComponent,
  init: initAndRender,
  initWithIntegration,
  initWithConfig: initWithConfig,
};
export default ho;
