/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
import axios, { AxiosResponse } from 'axios';
import {ErrorMessage, Field, getIn, useFormikContext} from 'formik';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { DefaultFieldComponentProps } from '../../../entities/default-field-component-props.entity';

const File: React.FunctionComponent<DefaultFieldComponentProps> = ({
  hoField,
  formik,
}: DefaultFieldComponentProps) => {
  const { setFieldValue, setFieldError } = useFormikContext();

  const onDrop = (acceptedFiles: File[]): void => {
    const data = new FormData();
    acceptedFiles.forEach((file) => {
      data.append('files[]', file);
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios.post(hoField.upload_url, data, config).then(
      (res: AxiosResponse<{ files: string[] }>) => {
        setFieldValue(hoField.name, res.data.files);
      },
      () => {
        setFieldError(hoField.name, 'Error');
      },
    );
  };

  return (
    <div className={`ho-field ${hoField.css_class ? hoField.css_class : ''} ${(getIn(formik.errors, hoField.name) && getIn(formik.touched, hoField.name)) ? 'ho-field-error-outer' : ''}`}>
      <label
        htmlFor={hoField.name}
        className={`ho-label ${hoField.label_css_class ? hoField.label_css_class : ''}`}>
        {hoField.label}
        {hoField.required &&
        <span  className="ho-required-star">*</span>
        }
      </label>

      <Field
        className={`ho-input ${hoField.input_css_class ? hoField.input_css_class : ''}`}
        name={hoField.name}>
        {() => (
          <Dropzone onDrop={onDrop}>
            {({ acceptedFiles, getRootProps, getInputProps }) => (
                <div  className={'ho-dropzone-container'}>
                    <div
                      className={'ho-file-upload'}
                      style={{
                        minHeight: '100px',
                        border: '1px solid lightgray',
                        borderRadius: '8px',
                        textAlign: 'center',
                        padding: '20px 10px',
                      }}
                      {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button className="ho-file-btn" type="button">
                        Upload
                      </button>
                      <p className="ho-file-input-placeholder">{hoField.placeholder}</p>
                    </div>
                    <aside className="ho-file-input-file-list">
                      <ul>
                        {acceptedFiles.map((file) => (
                          <li key={file.name}>
                            {file.name}-{' '}
                            {file.size < 1000000
                              ? `${Math.round(file.size / 1000)} kb`
                              : `${Math.round(file.size / 1000000)} mb`}
                          </li>
                        ))}
                      </ul>
                    </aside>
                </div>
            )}
          </Dropzone>
        )}
      </Field>
      <span className="ho-help-text">{hoField.helptext}</span>
      <ErrorMessage name={hoField.name} component="div" className="ho-field-error" />
    </div>
  );
};

export default File;
