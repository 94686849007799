enum ActionType {
  booking = 'booking',
  formSubmission = 'form_submission',
  checkin = 'checkin',
  createMeetingExternal = 'create_meeting_external',
  login = 'login',
  logout = 'logout',
  registration = 'registration',
  singleChat = 'chat',
  rating = 'rating',
  meeting = 'meeting',
  singleSignOn = 'sso',
  changeLanguage = 'changeLanguage',
}
export default ActionType;
