const dev = {
  debug: true,
  useBugRepoter: false,
  pusher: {
    key: '3e3933e18511e6774dbf',
  },
  api: {
    // url: 'https://api.hello-one.de/api/',
    url: '/api/',
  },
  useCustomLocale: false,
  locale: 'de',
};

const stage = {
  debug: false,
  useBugRepoter: true,
  pusher: {
    key: 'd57011e90976d2abd1d7',
  },
  api: {
    url: '/api/',
  },
  useCustomLocale: false,
  locale: 'de',
};

const prod = {
  debug: false,
  useBugRepoter: true,
  pusher: {
    key: 'e5aa08d596ec30633463',
  },
  api: {
    url: '/api/',
  },
  useCustomLocale: false,
  locale: 'de',
};

let config = prod;

if (process.env.NODE_ENV === 'stage') {
  config = stage;
} else if (process.env.NODE_ENV === 'development') {
  config = dev;
}

if (document.currentScript.dataset.api) {
  config.api.url = document.currentScript.dataset.api + '/api/';
  console.log('Components api url set to: ' + config.api.url);
}

if (document.currentScript.dataset.locale) {
  config.useCustomLocale = true;
  config.locale = document.currentScript.dataset.locale;
}

export default {
  // Add common config values here
  ...config,
};
