import React from 'react';
import { useCheckboxContext } from './checkbox-context';

interface Props {
  value: string;
  label: string;
  className: string;
  useHtml: boolean;
}

const Checkbox: React.FC<Props> = ({ className, value, label, useHtml }: Props) => {
  const { field, helpers, formField } = useCheckboxContext();
  let checked = false;
    console.log(field.value);
  if (field.value) {
      if(!Array.isArray(field.value)) {
          //@ts-ignore
          field.value = field.value.split(',');
      }

      checked = Boolean(field.value.find((_) => _ === value));
  }

  return (
    <span className={className}>
      <label className="ho-check-label">
        <input
          {...field}
          className="ho-check-input"
          type="checkbox"
          checked={checked}
          onChange={() => {
            if (checked) {
              helpers.setValue(field.value.filter((_) => _ !== value));
            } else {
              helpers.setValue([...field.value, value]);
            }
          }}
        />
        {useHtml && <div dangerouslySetInnerHTML={{ __html: label }} />}
        {!useHtml && <div className="ho-check-label-text">{label}</div>}
      </label>
    </span>
  );
};

export default Checkbox;
