import * as React from 'react';
import { Field, ErrorMessage } from 'formik';

const PasswordConfirmation: React.FunctionComponent<{ labelText: string }> = (labelText) => {
    function validatePassword(value: string) {
        let error;
        if (value.length < 8) {
            error = 'Password must be at least 8 characters long!';
        }
        return error;
    }

  return (
    <div className="ho-field">
      <label className="ho-label" htmlFor="ho-password-confirmation-field">
          {labelText.labelText}
      </label>
      <Field
        className="ho-input"
        name="ho-password-confirmation-field"
        type="password"
        placeholder={labelText.labelText}
        autoComplete="current-password"
        required
        validate={validatePassword}
      />
      <ErrorMessage
        component="div"
        name="ho-password-confirmation-field"
        className="ho-field-error"
      />
    </div>
  );
};
export default PasswordConfirmation;
