enum FormFieldType {
  // input types
  text = 'text',
  email = 'email',
  password = 'password',
  checkbox = 'checkbox',
  number = 'number',
  phone = 'phone',
  date = 'date',
  radio = 'radio',
  file = 'file',
  range = 'range',

  // select element
  select = 'select',

  // textarea element
  textarea = 'textarea',

  // CONTENT ELEMENTS:

  html = 'html',
}

export default FormFieldType;
