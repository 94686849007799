import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import useSWR, { responseInterface } from 'swr';
import { createContainer } from 'unstated-next';
import defaultConfig from '../config/config';
import { FormFieldValue } from '../entities/form-field-value.entity';
import { Integration } from '../entities/integration.entity';
import FORM_MOCKDATA from '../utils/FORM_MOCKDATA';
import { FORM_INTEGRATION_MOCKDATA } from '../utils/FORM_INTEGRATION_MOCKDATA';
import Config from '../entities/config.entity';
import config from '../config/config';
import { AuthContainer } from './auth.store';

export interface ErrorResponse {
  statusCode?: number;
  message?: { error: string };
  payload?: {
    [key: string]: { errors: string[] };
  } & { errors: string[] };
}

interface HookInterface {
  useGetIntegration: (uuid: string) => responseInterface<Integration, ErrorResponse>;
  getIntegration: (uuid: string, config?: Config) => Promise<AxiosResponse<Integration>>;
  postIntegration: (
    url: string,
    values: Record<string, FormFieldValue>,
  ) => Promise<AxiosResponse<void>>;
  postResponse: AxiosResponse<void>;
  error: ErrorResponse;
  resetPostError: () => void;
}

const useIntegration = (): HookInterface => {
  const [postResponse, setPostResponse] = useState(null);
  const [error, setError] = useState<ErrorResponse>(null);

  const useGetIntegration = (uuid: string): responseInterface<Integration, ErrorResponse> => {
    let fqdn = defaultConfig.api.url + 'integrations/' + uuid;

    if (defaultConfig.useCustomLocale === true) {
      fqdn = fqdn + `?locale=${defaultConfig.locale}`;
      console.log('Locale forced to: ' + defaultConfig.locale);
    }

    return useSWR<Integration>(fqdn, (url) =>
      // FORM_INTEGRATION_MOCKDATA,
      axios
        .get(url)
        .then((res) => res.data.data)
        .catch((error) =>
          setError({ ...error.response?.data?.data, statusCode: error.response?.status }),
        ),
    );
  };

  const getIntegration = async (
    uuid: string,
    config?: Config,
  ): Promise<AxiosResponse<Integration>> => {
    try {
      let fqdn = defaultConfig.api.url + 'integrations/' + uuid;

      if (defaultConfig.useCustomLocale === true) {
        fqdn = fqdn + `?locale=${defaultConfig.locale}`;
        console.log('Locale forced to: ' + defaultConfig.locale);
      }

      const data = await axios.get(fqdn);
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const resetPostError = (): void => {
    setError(null);
  };

  const postIntegration = async (
    url: string,
    values: Record<string, FormFieldValue>,
  ): Promise<AxiosResponse<void>> => {
    try {
      const cookieUrl = `${config.api.url}u/csrf-cookie`;

      const data = await axios.get(cookieUrl).then(() => {
        return axios.post(url, { payload: values });
      });

      setPostResponse(data);
      return data;
    } catch (error) {
      setError(error.response?.data?.data);
      throw error;
    }
  };

  return {
    useGetIntegration,
    getIntegration,
    postIntegration,
    postResponse,
    error,
    resetPostError,
  };
};

export const IntegrationsContainer = createContainer(useIntegration);
