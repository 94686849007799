import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import useSWR, { responseInterface } from 'swr';
import { createContainer } from 'unstated-next';
import { ErrorResponse } from './integration.store';
import ChatRoom, { ChatMember } from '../entities/chat-room.entity';
import { FormFieldValue } from '../entities/form-field-value.entity';
import ChatMessages from '../entities/chat-messages.entity';
import config from '../config/config';
import Pusher from 'pusher-js';
import { AuthContainer } from './auth.store';

interface ChatInterface {
  useGetChatRooms: (url: string) => Promise<AxiosResponse<ChatRoom[]>>;
  useGetSingleChatRoom: (url: string, withLastMessage: boolean) => Promise<AxiosResponse<ChatRoom>>;
  useGetChatRoomMessages: (url: string) => Promise<AxiosResponse<ChatMessages[]>>;
  postMessage: (
    url: string,
    values: Record<string, FormFieldValue>,
  ) => Promise<AxiosResponse<object>>;
  postResponse: AxiosResponse<void>;
  error: ErrorResponse;
  resetPostError: () => void;
}

const useChat = (): ChatInterface => {
  const [postResponse, setPostResponse] = useState(null);
  const [error, setError] = useState<ErrorResponse>(null);
  const { getCSRFCookie } = AuthContainer.useContainer();

  const useGetChatRooms = async (url: string): Promise<AxiosResponse<ChatRoom[]>> => {
    try {
      const data = await axios.get(url);
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const useGetSingleChatRoom = async (
    url: string,
    withLastMessage = true,
  ): Promise<AxiosResponse<ChatRoom>> => {
    try {
      const data = await axios.get(url + '?withLastMessage=' + withLastMessage);
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const useGetChatRoomMessages = async (url: string): Promise<AxiosResponse<ChatMessages[]>> => {
    try {
      const data = await axios.get(url);
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const resetPostError = (): void => {
    setError(null);
  };

  const postMessage = async (
    url: string,
    values: Record<string, FormFieldValue>,
  ): Promise<AxiosResponse<ChatMessages>> => {
    try {
      const data = await axios.post(url, { payload: values });
      setPostResponse(data.data);
      return await getCSRFCookie().then(() => {
        return data.data;
      });
    } catch (error) {
      setError(error.response?.data?.data);
      throw error;
    }
  };

  if (config.debug) {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;
  }

  return {
    useGetChatRooms,
    useGetSingleChatRoom,
    useGetChatRoomMessages,
    postMessage,
    postResponse,
    error,
    resetPostError,
  } as ChatInterface;
};

export const ChatRoomsContainer = createContainer(useChat);
