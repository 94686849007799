import React, { ReactElement, useEffect, useState } from 'react';
import ActionType from '../entities/action-type.enum';
import FormIntegration from '../entities/form-integration.entity';
import LoginIntegration from '../entities/login-integration.entity';
import { AuthContainer } from '../stores/auth.store';
import { IntegrationsContainer } from '../stores/integration.store';
import { HoFormComponent } from './forms/ho-form.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import ChatIntegration from '../entities/chat-integration.entity';
import { ChatRoomsContainer } from '../stores/chat.store';
import { ChatComponent } from './chat/chat.component';
import { RatingComponent } from './rating/rating.component';
import RatingIntegration from '../entities/rating-integration.entity';
import { RatingContainer } from '../stores/rating.store';
import { MeetingContainer } from '../stores/meeting.store';
import { LoginContainer } from '../stores/login.store';
import { MeetingComponent } from './meeting/meeting.component';
import MeetingIntegration from '../entities/meeting-integration.entity';
import { SingleSignOnComponent } from './sso/sso.component';
import SingleSignOnIntegration from '../entities/single-sign-on-integration.entity';
import ChangeLanguageIntegration from '../entities/change-language-integration.entity';
import { ChangeLanguageComponent } from './changeLanguage/change-language';
import { ChangeLanguageContainer } from '../stores/changeLanguage.store';
import Integration from '../entities/integration.entity';
import { AxiosResponse } from 'axios';
import Config from '../entities/config.entity';
import LogoutIntegration from '../entities/logout-integration.entity';

export interface Props {
  uuid: string;
  config?: Config;
}

export function App({ uuid, config = null }: Props): ReactElement {
  const { getIntegration, error } = IntegrationsContainer.useContainer();
  const { getCSRFCookie } = AuthContainer.useContainer();
  const [csrfCookie, setCsrfCookie] = useState(false);
  const [integration, setIntegration] = useState(null);

  const FetchIntegration = (config: Config = null): void => {
    if (integration != null) {
      return;
    }
    if (config.integration) {
      setIntegration(config.integration);
      return;
    }
    getIntegration(uuid, config).then((res: AxiosResponse<Integration>) => {
      setIntegration(res.data);
    });
  };

  FetchIntegration(config);

  useEffect(() => {
    if (!csrfCookie && integration && integration.type != ActionType.booking) {
      getCSRFCookie().then(() => setCsrfCookie(true));
    }
  }, [csrfCookie, setCsrfCookie, getCSRFCookie]);

  if (error && integration.type != ActionType.registration) {
    let errorMessage =
      'Sorry - we are unable to process this request. ' +
      (error.message?.error !== undefined ? error.message?.error : 'Please reload the page.');

    if (error.statusCode === 401) {
      errorMessage = 'Login required / Login erforderlich!';
    }

    if (error.message?.error !== 'ho-password-field missing') {
      return <div className={'ho-integration-error-message'}>{errorMessage}</div>;
    }
  }

  if (!integration) {
    return (
      <div className="ho-integration-loading-message integration-loading-message">
        Component is loading... If this message persists we may have problems to load this component
        at the moment
      </div>
    );
  }

  switch (integration?.type) {
    case ActionType.createMeetingExternal:
    case ActionType.formSubmission:
    case ActionType.booking:
    case ActionType.registration:
      return <HoFormComponent integration={integration as FormIntegration} />;
    case ActionType.login:
      return (
        <LoginContainer.Provider>
          <LoginComponent integration={integration as LoginIntegration} />
        </LoginContainer.Provider>
      );
    case ActionType.logout:
      return <LogoutComponent integration={integration as LogoutIntegration} />;
    case ActionType.singleChat:
      return (
        <ChatRoomsContainer.Provider>
          <ChatComponent integration={integration as ChatIntegration} />
        </ChatRoomsContainer.Provider>
      );
    case ActionType.rating:
      return (
        <RatingContainer.Provider>
          <RatingComponent integration={integration as RatingIntegration} />
        </RatingContainer.Provider>
      );
    case ActionType.meeting:
      return (
        <MeetingContainer.Provider>
          <MeetingComponent integration={integration as MeetingIntegration} />
        </MeetingContainer.Provider>
      );
    case ActionType.singleSignOn:
      return <SingleSignOnComponent integration={integration as SingleSignOnIntegration} />;
    case ActionType.changeLanguage:
      return (
        <ChangeLanguageContainer.Provider>
          <ChangeLanguageComponent integration={integration as ChangeLanguageIntegration} />
        </ChangeLanguageContainer.Provider>
      );
    default:
      break;
  }
}
